import React from "react";
import { AXIOSLINK } from "../../utils/constants";
const axios = require('axios')
import './manage.css'
import Swal from 'sweetalert2'

export default class Upload extends React.Component{

    state= {
        file:null
    }
    handleChange = (event) =>{
        console.log(event.target.files)
        this.setState({file:event.target.files[0]})
    }

    handleSubmit = async (event) =>{
        event.preventDefault()
        
        console.log(this.state.file)
 
        const data = new FormData()
        data.append('files',this.state.file)

        var getId = this.props.userId
        console.log(getId)

        const upload_res = await axios({
            method:'POST',
            url: AXIOSLINK + 'upload',
            data
        })
        

        const imageId = upload_res.data[0].id
        axios.put(AXIOSLINK + 'users/'+ getId,{ UserImage:imageId})
        .then(function(result){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Uploaded',
                showConfirmButton: false,
                timer: 3000
                })
        })
        
        console.log(upload_res)
    }
render(){
return(
  <div className="FilesUpload">
      <form onSubmit={this.handleSubmit}>
          <input onChange={this.handleChange}
          type="file" />
          <button class="btn btn-success uploadbtn" style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"15px",
              width:"13%",height:"35px",lineHeight:"10px"}}>Upload</button>
      </form>
  </div>
  
)
}
}
