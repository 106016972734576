import React from "react";
import Layout from "../components/layout";
import { Link } from 'gatsby'

import UpdateUserinfo from "../components/ManageProfile/UpdateUserInfo";

const UpdateProfile = () =>{
    const UserInfo = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('UserInfo')) : null
    const updateuserprofile = typeof window !== 'undefined' ?sessionStorage.getItem("updateuserprofile") : null
    typeof window !== 'undefined' ?sessionStorage.clear() : null

     
    if(updateuserprofile == "true"){
        return <UpdateUserinfo  userdetails = {UserInfo}/>
    }

return(
   <Layout>
    <Link to="/ManageAccount">
    {' '}
    <span style={{ fontSize: '25px' }}>←</span> Back to Manage Account Page
    </Link>
   </Layout>
)

  
}

export default UpdateProfile