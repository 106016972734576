import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../layout";
import './manage.css'
import {
    MDBBtn,
    MDBIcon,
  } from 'mdbreact'
import { AXIOSLINK } from "../../utils/constants";
const axios = require('axios')
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import MDEditor from '@uiw/react-md-editor';
import { Link } from 'gatsby'
import Upload from "./Upload";

const UpdateUserinfo = (props) =>{
    const data = useStaticQuery(graphql`
      {
        allStrapiManageAccounts {
          nodes {
            InterpreterAddress
            InterpreterBusinessNumber
            InterpreterCity
            InterpreterEmail
            InterpreterPhoneNumber
            InterpreterState
            InterpreterVideoNumber
            InterpreterZipcode
            address
            city
            email
            firstname
            lastname
            licensenumber
            ManageAccountContent
            interpretercredentials
            opt
            phonenumber
            state
            EmailFieldInformation
            zipcode
            PageBG
            ManageDynamicZone {
                TitleAlignment
                TitleFontColor
                TitleFontFamily
                TitleFontSize
                TitleFontWeight
                TitleName
              }
          }
        }
      }
    `)
    
    var updateInfo = data.allStrapiManageAccounts.nodes
    const ManageDynamicZone = updateInfo[0].ManageDynamicZone[0]

    const Data1 = {
        firstName: props.userdetails.firstName,
        lastName: props.userdetails.lastName,
        email: props.userdetails.email,
        IowaLicenseNumber: props.userdetails.licenseNumber,
        InterpretingCredentials: props.userdetails.interpreterCredentials,
        address: props.userdetails.address,
        city: props.userdetails.city,
        state: props.userdetails.state,
        zipcode: props.userdetails.zipcode,
        PhoneNumber: props.userdetails.phoneNumber,
        opt:props.userdetails.opt,
        InterpreterSearchAddress:props.userdetails.InterpreterSearchAddress,
        InterpreterSearchCity:props.userdetails.InterpreterSearchCity,
        InterpreterSearchState:props.userdetails.InterpreterSearchState,
        InterpreterSearchZipcode:props.userdetails.InterpreterSearchZipcode,
        InterpreterSearchEmail:props.userdetails.InterpreterSearchEmail,
        InterpreterSearchPhoneNumber:props.userdetails.InterpreterSearchPhoneNumber,
        InterpreterSearchBusinessNumber:props.userdetails.InterpreterSearchBusinessNumber,
        InterpreterSearchVideoNumber:props.userdetails.InterpreterSearchVideoNumber,
      }

      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({ defaultValues: Data1 })

      var UserId = props.userdetails.id;
     
  


      const UpdateData = e => {
        e.preventDefault()
    
      var optOut = disable();
        const data = {
          Id:props.userdetails.id,
          FirstName: document.getElementById('FirstName').value,
          LastName: document.getElementById('LastName').value,
          IOWALicenseNumber: document.getElementById('IowaLicenseNumber').value,
          InterepeterCredentials: document.getElementById('InterpretingCredentials').value,
          Address: document.getElementById('Address').value,
          City: document.getElementById('city').value,
          State: document.getElementById('state').value,
          Zipcode: document.getElementById('zipcode').value,
          email: document.getElementById('emailAddress').value,
          UserPhoneNumber: document.getElementById('PreferredPhoneNumber').value,
          Opt:optOut,
          InterpreterSearchAddress:document.getElementById('InterpreterAddress').value,
          InterpreterSearchCity:document.getElementById('Interpretercity').value,
          InterpreterSearchState:document.getElementById('Interpreterstate').value,
          InterpreterSearchZipcode:document.getElementById('Interpreterzipcode').value,
          InterpreterSearchEmail:document.getElementById('InterpreteremailAddress').value,
          InterpreterSearchPhoneNumber:document.getElementById('InterpreterPreferredPhoneNumber').value,
          InterpreterSearchBusinessNumber:document.getElementById('InterpreterPreferredBusinessNumber').value,
          InterpreterSearchVideoNumber:document.getElementById('InterpreterPreferredVideoNumber').value,
          
        }

        var value1 = AXIOSLINK + 'users/'
        var value2 = data.Id
        var value3 = value1 + value2


          Swal.fire({
            title: 'Do you want to Update Your profile?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#00c851',
          }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.put(value3, data)
                .then(result => {
                console.log(result)
                Swal.fire({
                    title: ' Thank You',
                    text: 'Your Details Updated Successfully.',
                    confirmButtonColor: '#00c851',
                  }).then(response => {
                    /* Read more about isConfirmed, isDenied below */
                    if (response.isConfirmed) {
                      window.location.pathname = '/home'
                    } 
                  })

                 })
                .catch(err =>{
                console.log(err)
                })
            } 
        })

        
    }

    function disable(){
      var optOut = null
      var checkBox = document.getElementById("optOut");
      if (checkBox.checked == true){
        optOut = true
        document.getElementById("tablinks2").hidden = false;
        
      } else {
        optOut = false;
        document.getElementById("tablinks2").hidden = true;
      }
      console.log(optOut)
      return optOut;
    }

    function contact(){
           
         document.getElementById("tab2").hidden = true;
         document.getElementById("tab1").hidden = false;
         document.getElementById("tablinks1").style.backgroundColor="#ccc";
         document.getElementById("tablinks2").style.backgroundColor="#f1f1f1";
        
    }
    function searchdetails(){
        
          document.getElementById("tab2").hidden = false;
          document.getElementById("tab1").hidden = true;
          document.getElementById("tablinks1").style.backgroundColor="#f1f1f1";
          document.getElementById("tablinks2").style.backgroundColor="#ccc";
       
    }
       
    return(
      <Layout>
      <div
        className="card UpdateCard"
        style={{
          backgroundColor:updateInfo[0].PageBG,
        }}
      >
        <div className="container pageContainer">
          <h2
            class="membershipTitle"
            style={{
              fontFamily: ManageDynamicZone.TitleFontFamily,
              fontSize: ManageDynamicZone.TitleFontSize,
              fontWeight: ManageDynamicZone.TitleFontWeight,
              textAlign: ManageDynamicZone.TitleAlignment,
              color: ManageDynamicZone.TitleFontColor,
            }}
          >
            {ManageDynamicZone.TitleName}
          </h2>
          <br/>
          <div class="center">
            <h5 >Welcome, {Data1.firstName} {Data1.lastName} </h5>
            <h5>{updateInfo[0].ManageAccountContent}</h5>
          </div>
          <br/>
          {/* ************************* */}
          <div class="form-outline">
            <label htmlFor="opt" style={{paddingRight:"20px"}}>
                {' '}
                <b>Opt-in to ISRID interpreter search </b>
            </label>

            <label class="switch">
              <input type="checkbox" name='optOut' id="optOut" required {...register('opt')} onClick={disable}/>
              <span class="slider round"></span>
            </label>
            </div>

            {/* ************************* */}
          <div class="tab">
            <button id="tablinks1" style={{backgroundColor:"#ccc"}} onClick={contact}>ISIRD Contact Details</button>
            <button id="tablinks2" onClick={searchdetails} >Interpreter Search Details</button>
          </div>
          <br/>
          <form action="">
            
            {/************************** */}
            <div id="tab1">
            <div class="form-outline">
              <label htmlFor="email">
                {' '}
                <b>{updateInfo[0].email}</b>
              </label>
              <br />
              <input
                type="email"
                {...register('email')}
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                id="emailAddress"
                required
                disabled
                minlength="2"
                maxlength="50"
              />
            </div>
            <p><MDEditor.Markdown source={updateInfo[0].EmailFieldInformation}/></p>
            <br/>
            {/************************** */}
            <label htmlFor="firstname">
              {' '}
              <b>{updateInfo[0].firstname}</b>
              &nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('firstName')}
              class="form-control"
              placeholder="Enter Your First name"
              name="FirstName"
              id="FirstName"
              required
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="lastname">
              {' '}
              <b>{updateInfo[0].lastname}</b>
              &nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('lastName')}
              class="form-control"
              placeholder="Enter Your Last name"
              name="LastName"
              id="LastName"
              required
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="licenseNumber">
              {' '}
              <b>{updateInfo[0].licensenumber} </b>
              &nbsp;
            </label>
            &nbsp;
            <input
              type="number"
              {...register('IowaLicenseNumber')}
              class="form-control"
              placeholder="Enter IOWA license number"
              name="IowaLicenseNumber"
              id="IowaLicenseNumber"
              required
              minlength="2"
              maxlength="15"
            />
           
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="InterpretingCredentials">
                {' '}
                <b>{updateInfo[0].interpretercredentials}</b>
              </label>
              <br />
              <input
                type="text"
                {...register('InterpretingCredentials')}
                class="form-control"
                placeholder="Enter Interpreting Credentials"
                name="InterpretingCredentials"
                id="InterpretingCredentials"
                minlength="2"
                maxlength="30"
              />
              <br />
            </div>
            <div class="form-outline">
              <label htmlFor="Address1">
                {' '}
                <b>{updateInfo[0].address}</b>
              </label>
              <br />
              <input
                type="text"
                {...register('address')}
                class="form-control"
                placeholder="Enter your Address1"
                name="Address"
                id="Address"
                required
                minlength="10"
                maxlength="50"
              />
              <br />
            </div>
    
            {/* ************************* */}
            <label htmlFor="city">
              {' '}
              <b>{updateInfo[0].city}</b>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('city')}
              class="form-control"
              placeholder="Enter city"
              name="city"
              id="city"
              required
              minlength="2"
              maxlength="15"
            />
            <br />
            <label htmlFor="state">
              {' '}
              <b>{updateInfo[0].state}</b>&nbsp;
            </label>
            &nbsp;
            <select
            type="text"
            {...register('state')}
            class="form-control"
            placeholder="Enter state"
            name="state"
            id="state"
            required
            >
             <option hidden></option> 
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
            <br />
            <label htmlFor="zip">
              {' '}
              <b>{updateInfo[0].zipcode}</b>&nbsp;
            </label>
            &nbsp;
            <input
              type="number"
              {...register('zipcode')}
              class="form-control"
              placeholder="Enter ZIP"
              name="zipcode"
              id="zipcode"
              required
              minlength="5"
              maxlength="5"
            />
            <br />
            {/* ************************* */}
            
            <br />
            <div class="form-outline">
              <label htmlFor="phoneNumber">
                {' '}
                <b>{updateInfo[0].phonenumber}</b>
              </label>
              <br />
              <input 
                type="number"
                {...register('PhoneNumber')}
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Phone number"
                name="PreferredPhoneNumber"
                id="PreferredPhoneNumber"
                required
                minlength="10"
                maxlength="12"
              /> 
            </div>
            <br />
           
            {/* ************************* */}
            {/* <div class="form-outline">
              <label htmlFor="phoneNumber">
                {' '}
                <b>Interpreter Search Data</b>
              </label>
              <br />
              <textarea id="interpreterSearchData" class="form-control" name="interpreterSearchData" {...register('interpretersearchdata')}  rows="10" cols="100"/>
              
            </div>
            <br /> */}
            {/* ************************* */}
            
            <div className="text-center mt-4">
              {/* <MDBBtn color="success"  outline type="submit" onClick={UpdateData} >
              Click here to Update 
              </MDBBtn> */}
              <button type="submit" class="btn btn-success updatebtn" onClick={UpdateData}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"27%"}}>Click here to Update</button>
            </div>
            {/* </div> */}
            </div>
          </form>
          
          <form>
            <div id="tab2" hidden>
            <div class="form-outline">
              <label htmlFor="Address">
                {' '}
                <b>{updateInfo[0].InterpreterAddress}</b>
              </label>
              <br />
              <input
                type="text"
               {...register('InterpreterSearchAddress')}
                class="form-control"
                placeholder="Enter your Interpreter search Address"
                name="InterpreterAddress"
                id="InterpreterAddress"
                required
                minlength="10"
                maxlength="50"
              />
              <br />
            </div>
    
            {/* ************************* */}
            <label htmlFor="city">
              {' '}
              <b>{updateInfo[0].InterpreterCity}</b>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              {...register('InterpreterSearchCity')}
              class="form-control"
              placeholder="Enter Interpreter search city"
              name="Interpretercity"
              id="Interpretercity"
              required
              minlength="2"
              maxlength="15"
            />
            <br />
            <label htmlFor="state">
              {' '}
              <b>{updateInfo[0].InterpreterState}</b>&nbsp;
            </label>
            &nbsp;
            <select
            type="text"
            {...register('InterpreterSearchState')}
            class="form-control"
            placeholder="Enter Interpreter search state"
            name="Interpreterstate"
            id="Interpreterstate"
            required
            >
             <option hidden></option> 
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="District Of Columbia">District Of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
            <br />
            <label htmlFor="zip">
              {' '}
              <b>{updateInfo[0].InterpreterZipcode}</b>&nbsp;
            </label>
            &nbsp;
            <input
              type="number"
              {...register('InterpreterSearchZipcode')}
              class="form-control"
              placeholder="Enter Interpreter search ZIP"
              name="Interpreterzipcode"
              id="Interpreterzipcode"
              required
              minlength="5"
              maxlength="5"
            />
            <br />
            {/* ************************* */}
        
            <div class="form-outline">
              <label htmlFor="email">
                {' '}
                <b>{updateInfo[0].InterpreterEmail}</b>
              </label>
              <br />
              <input
                type="email"
                {...register('InterpreterSearchEmail')}
                class="form-control"
                placeholder="Enter your Interpreter search email address"
                class="form-control"
                name="InterpreteremailAddress"
                id="InterpreteremailAddress"
                required
                minlength="2"
                maxlength="50"
              />
            </div>
           {/* ************************* */}
           <div class="form-outline">
              <label htmlFor="phoneNumber">
                {' '}
                <b>{updateInfo[0].InterpreterPhoneNumber}</b>
              </label>
              <br />
              <input
                type="number"
                {...register('InterpreterSearchPhoneNumber')}
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Interpreter search Phonenumber"
                name="InterpreterPreferredPhoneNumber"
                id="InterpreterPreferredPhoneNumber"
                required
                minlength="10"
                maxlength="12"
              />
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="businessNumber">
                {' '}
                <b>{updateInfo[0].InterpreterBusinessNumber}</b>
              </label>
              <br />
              <input
                type="number"
                {...register('InterpreterSearchBusinessNumber')}
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Interpreter search businessnumber"
                name="InterpreterPreferredBusinessNumber"
                id="InterpreterPreferredBusinessNumber"
                required
                minlength="10"
                maxlength="12"
              />
            </div>
            <br />
            {/* ************************* */}
            <div class="form-outline">
              <label htmlFor="VideoNumber">
                {' '}
                <b>{updateInfo[0].InterpreterVideoNumber}</b>
              </label>
              <br />
              <input
                type="number"
                {...register('InterpreterSearchVideoNumber')}
                class="form-control"
                inputmode="numeric"
                placeholder="Enter your Interpreter search Videonumber"
                name="InterpreterPreferredVideoNumber"
                id="InterpreterPreferredVideoNumber"
                required
                minlength="10"
                maxlength="12"
              />
            </div>
            <br />
            {/* ************************* */}
            <label htmlFor="Image">
                {' '}
                <b>Select a Profile Image (Portrait type image of maximum size 100kb, File type: JPG/PNG )</b>
            </label>
              <br />
            <Upload userId={UserId} />
            <br />
            {/* ************************* */}
           <div className="text-center mt-4">
              {/* <MDBBtn color="success"  outline type="submit" onClick={UpdateData} >
              Click here to Update 
              </MDBBtn> */}
              <button type="submit" class="btn btn-success updatebtn" onClick={UpdateData}
              style={{backgroundColor:"rgb(0, 128, 0)",
              borderRadius:"7px",
              fontSize:"16px",
              width:"27%"}}>Click here to Update</button>
            </div>
            {/* ************************* */}
            
            </div>
          </form>



        </div>
      </div>
      </Layout>
  )
}

export default UpdateUserinfo